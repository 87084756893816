export const consent_html_text =
    "<p>Dear Student,</p>"
    + "<p>Thank you for your interest in applying for overseas university / institute / training program through Aliff Overseas"
    + "    Consultants (a</p>"
    + "<p>division of Aliff Overseas Pvt. Ltd.). All our services including university application services are in accordance"
    + "    with terms and</p>"
    + "<p>conditions described in the subsequent pages.</p>"
    + "<p>Please read the following terms and conditions carefully. Authorize each page with yours and your parents / guardians"
    + "    full</p>"
    + "<p>signature, confirming you your acceptance of the same.</p>"
    + "<p>1. Definition &amp; Nomenclature</p>"
    + "<p>1.1. Aliff, Aliff Overseas and Aliff Overseas Consultants, Aliff Overseas Education and Aliff Overseas Pvt. Ltd. will"
    + "</p>"
    + "<p>henceforth be mentioned / referred to as AOPL in this document.</p>"
    + "<p>1.2. Overseas Institution means International University / College / School / Organization or any other educational"
    + "</p>"
    + "<p>institute in countries apart from India.</p>"
    + "<p>1.3. Program means course / training / internship / subject / paper / module designed, run and certified by Overseas"
    + "</p>"
    + "<p>Institutions (refer 1.2) that may be recommended but not controlled by AOPL.</p>"
    + "<p>1.4. Registration, Application, Visa Processing &amp; other services Fee means a sum of money charged by AOPL towards"
    + "</p>"
    + "<p>comprehensive consulting services.</p>"
    + "<p>1.5. Application Fee means a sum of money charged by Overseas Institution as per its rules and regulations for"
    + "    accepting</p>"
    + "<p>student application.</p>"
    + "<p>1.6. Tuition fee, College fee, Program fee, Semester fee, Module fee and Course fee are all the same and will"
    + "    henceforth</p>"
    + "<p>be mentioned / referred to as Tuition Fee in this document. Overseas Institution may charge tuition fee in a single"
    + "    or</p>"
    + "<p>multiple installments depending upon its rules.</p>"
    + "<p>1.7. Associate means an individual or an organization liaising with AOPL on behalf of student / parent / Overseas</p>"
    + "<p>Institution.</p>"
    + "<p>1.8. Third party services are those offered by companies like but not limited to Airline, Travel Agents, Foreign"
    + "    Exchange</p>"
    + "<p>Provider, Insurance Companies, Banks &amp; Financial Institutions, Accommodation provider in foreign country,"
    + "    Agencies</p>"
    + "<p>mediating AOPL and Overseas Institution etc.</p>"
    + "<p>1.9. Communication Channel means different medium of / for communication like website, blogs, social media platforms,"
    + "</p>"
    + "<p>messengers, instant messengers, text messaging / sms, mms, telephone, email etc.</p>"
    + "<p>1.10. Student means a person who intend to enroll for any program (refer 1.3) in any overseas institution (refer 1.2)"
    + "    using AOPL</p>"
    + "<p>services.</p>"
    + "<p>1.11. Parent / Guardian means biological / legal custodian of the student (refer 1.10).</p>"
    + "<p>2. Limitation of services offered by AOPL</p>"
    + "<p>2.1. AOPL is an authorized education consultant. AOPL does not act as a guardian of the student in anyway. However we"
    + "    may,</p>"
    + "<p>at our discretion act as a link between the student and / or parent and the International Student department of the"
    + "</p>"
    + "<p>Overseas Institution. All decision taken by the Overseas Institution is deemed to be final and binding on the student"
    + "    and</p>"
    + "<p>parent.</p>"
    + "<p>2.2. AOPLmay recommend programs based on the academics and profile of a student. Such programs are not designed, run"
    + "    or</p>"
    + "<p>certified by AOPL. The final decision in the selection of any programs is the absolute discretion and responsibility"
    + "    of the</p>"
    + "<p>student and / or parent.</p>"
    + "<p>2.3. AOPL liabilities and responsibilities ceases once the student joins the Overseas Institution.</p>"
    + "<p>2.4. AOPL is not responsible for discontinuation of any Program / Department / Faculty by Overseas Institution, on or"
    + "    after</p>"
    + "<p>admission.</p>"
    + "<p>2.5. Closure of Overseas Institution due to bankruptcy, Natural calamity, Economic Downfall or any other reason on or"
    + "    after</p>"
    + "<p>admission is beyond the control of AOPL.</p>"
    + "<p>2.6. Overseas Institution may introduce the new / additional mode of study(s) and / or the language of instruction on"
    + "    or after</p>"
    + "<p>admission with or without notice at its own discretion.</p>"
    + "<p>2.7. Overseas Institution may change fees on or after admission, with or without notice. Such changes are on students"
    + "    /</p>"
    + "<p>parents. AOPLwill not be liable (financially or otherwise) for such changes.</p>"
    + "<p>2.8. AOPL in not responsible for any change in Affiliations / Degree Awarding Bodies / Association Partners of the"
    + "    Overseas</p>"
    + "<p>Institution on or after admission, with or without notice.</p>"
    + "<p>2.9. AOPL is not in control of or responsible for any change in rule pertaining to visa, employment, part-time"
    + "    employment,</p>"
    + "<p>post-study stay back options, admission criteria (either in force, drafted, proposed or yet to be proposed) by local"
    + "    and /</p>"
    + "<p>or foreign government or immigration department.</p>"
    + "<p>2.10. AOPL does not own, operate or control any Third Party Services mentioned in any of our communications"
    + "    (brochures,</p>"
    + "<p>website, advertisement etc.). AOPL in no way, can or will influence any decision taken by the Third Party Service</p>"
    + "<p>Provider(s). AOPL cannot be held responsible for delay / damage (financial or otherwise) / hardship that may be"
    + "    caused</p>"
    + "<p>upon availing such services.</p>"
    + "<p>3. Media rights, Intellectual property rights and Privacy.</p>"
    + "<p>3.1. Student / parent hereby willingly authorize AOPL to record / document (electronically or otherwise) feedback /"
    + "    testimonial</p>"
    + "<p>and use them in future for promotional activities, electronically or otherwise without paying royalty for the same."
    + "</p>"
    + "<p>3.2. All information submitted and shared by student / parent to AOPL might be shared with Third Party Service"
    + "    provider for the</p>"
    + "<p>purpose of offering their services. AOPL reserves the right to use such information in future for the sole purpose of"
    + "    AOPL</p>"
    + "<p>and / or for any of its present or yet to be formed subsidiaries.</p>"
    + "<p>3.3. Further it is implied that AOPL reserves the right to use data provided by student / parent as reference in"
    + "    future for</p>"
    + "<p>the sole purpose of AOPL and / or for any of its present or yet to be formed subsidiaries.</p>"
    + "<p>3.4. Any logos, trademarks, literatures, Audio, Videos and intellectual properties use by AOPL in its communication"
    + "    (brochures,</p>"
    + "<p>website, advertisement etc.) apart from those belonging to AOPL are properties of their respective owners. AOPL use"
    + "    them</p>"
    + "<p>in their communication (brochures, website, advertisement etc.) for the sole purpose of representation /</p>"
    + "<p>recommendation to students / parents.</p>"
    + "<p>3.5. Documents, formats, checklist, literatures etc. provided by AOPL to students from time to time are properties of"
    + "    AOPL and</p>"
    + "<p>are confidential. All such documents are for internal and official purpose only. These documents, formats, checklists"
    + "</p>"
    + "<p>literatures etc. cannot be shared, distributed and / or made public by student / parent. Any distribution,"
    + "    reproduction</p>"
    + "<p>(print or electronic) or misuse of such said document without permission of AOPL is legal offence. Appropriate legal"
    + "    action</p>"
    + "<p>will be taken by AOPL for such distribution, preproduction or misuse.</p>"
    + "<p>4. Terms &amp; Conditions of services offered by AOPL</p>"
    + "<p>4.1. AOPL acts as an overseas education consultant and provide information on program, universities and countries on"
    + "    the</p>"
    + "<p>basis of best effort. And may discretionarily assist students in submitting application for programs / Overseas"
    + "    Institution</p>"
    + "<p>selected by the student / parent.</p>"
    + "<p>4.2. Any brochure, communication, document or promotional offer issued by AOPL, do not constitute an invitation to"
    + "    enroll for</p>"
    + "<p>any program or any university. Students' application to any program / university is thus voluntary and is not imposed"
    + "    /</p>"
    + "<p>forced by AOPL.</p>"
    + "<p>4.3. Student and parent / guardian should satisfy themselves fully with all the information regarding the program and"
    + "    / or</p>"
    + "<p>overseas institution. It is implied that the student will and has independently verified all the information provided"
    + "    by</p>"
    + "<p>AOPL.</p>"
    + "<p>4.4. Submitting university application through AOPL does not assure / guarantee admission to any program in any"
    + "    overseas</p>"
    + "<p>institution selected by student / parent.</p>"
    + "<p>4.5. Submitting university application through AOPL does not constitute a contract between the two parties (student"
    + "    &amp;</p>"
    + "<p>Overseas Institution) in any way, unless agreed upon mutually by the said parties (student &amp; Overseas"
    + "    Institution).</p>"
    + "<p>4.6. It is implied that the student will abide by the terms and conditions mentioned by the Overseas Institution for"
    + "    the chosen</p>"
    + "<p>program.</p>"
    + "<p>4.7. It is implied that the student / parent fully understand the cost involved / financial implications of the"
    + "    chosen program /</p>"
    + "<p>Overseas Institution.</p>"
    + "<p>4.8. Students should submit all the necessary documents in prescribed format as required for the chosen Overseas</p>"
    + "<p>Institution. Any delay or failure in doing so and the consequences of such delay or failure will be sole"
    + "    responsibility</p>"
    + "<p>of the student.</p>"
    + "<p>4.9. AOPLwill not be responsible and / or liable for denial of admission by Overseas Institution on account of any"
    + "    reason.</p>"
    + "<p>4.10. Delay or no response by overseas institute for the application submitted is out of purview of AOPL.</p>"
    + "<p>4.11. AOPL will not be responsible for any loss or damage of documents either in transit or already submitted with"
    + "    Overseas</p>"
    + "<p>Institution.</p>"
    + "<p>4.12. Student is required to procure passport (with a minimum validity of 6 to 12 months) by the time AOPL files"
    + "    admission</p>"
    + "<p>application. Any delay in procurement of passport and consequences arising of such situations like denial of"
    + "    admission,</p>"
    + "<p>denial of visa etc. are not the responsibility of AOPL.</p>"
    + "<p>4.13. The cost of processing, printing and photocopying student's documents and additional expenses incurred for but"
    + "    not</p>"
    + "<p>limited to translation fee, visa fee, attestation fee, university application fee, coaching &amp; exam fee, medical"
    + "    test,</p>"
    + "<p>insurance, bank loan processing fee etc. unless otherwise agreed upon, will be on students / parent.</p>"
    + "<p>4.14. AOPLwill not be responsible and / or liable for denial of visa and / or denial of permission to enter the"
    + "    desired country by</p>"
    + "<p>the consulate / embassy / immigration department on any ground.</p>"
    + "<p>4.15. AOPL will not be responsible for any denial, delay, mistake, misprint or delay caused due to resubmission,"
    + "    correction of</p>"
    + "<p>VISA. This service is beyond the control of AOPL.</p>"
    + "<p>5. Rejection or Cancellation of admission / readmission and expulsion or non-completion of program</p>"
    + "<p>5.1. Student / parent should disclose information true to their knowledge. Denial / rejection of admission or"
    + "    cancellation of</p>"
    + "<p>admission after confirmation by Overseas Institution due to suppression of facts / information or submission of wrong"
    + "</p>"
    + "<p>information are not the responsibilities of AOPL. Penalties, fines or any other financial or non-financial"
    + "    implication</p>"
    + "<p>arising due to such situation is the sole liability of student / parent.</p>"
    + "<p>5.2. Cancellation / rejection of admission due to non-payment / late payment of fees to the Overseas Institution are"
    + "    out of</p>"
    + "<p>purview of AOPL. Such cancellation / rejection are not the responsibility or liability of AOPL. Further, financial"
    + "</p>"
    + "<p>implications (if any) arising out of such situations are the sole responsibility of the student / parent and has to"
    + "    be fully</p>"
    + "<p>borne by them.</p>"
    + "<p>5.3. Overseas institution reserve the right to revoke admission issued to a student with or without notice. Any such"
    + "    situation</p>"
    + "<p>is beyond control of AOPL and cannot be held liable for the same.</p>"
    + "<p>5.4. Re-admission to consecutive years / semester of a program is the responsibility of the student / parent. AOPL"
    + "    may assist</p>"
    + "<p>at its discretion. Further cancellation / rejection of re-admission due to non-payment or any other reason is not the"
    + "</p>"
    + "<p>responsibility of AOPL.</p>"
    + "<p>5.5. AOPL reserves the right to recommend cancellation of admission due to non-payment of full or part of the AOPL"
    + "</p>"
    + "<p>service charges.</p>"
    + "<p>5.6. Student may get expelled from Overseas Institution due to misconduct, poor academic performance, poor"
    + "    attendance,</p>"
    + "<p>dishonoring the law of the land, criminal, terrorist activity or any other reason is absolutely out of the purview of"
    + "    AOPL. It</p>"
    + "<p>is implied that AOPL is not responsible (financial or otherwise) for such incident.</p>"
    + "<p>5.7. AOPL is not responsible or liable for non-completion of a program fully or partially due to student's personal"
    + "</p>"
    + "<p>reasons like ill health, home sickness, cultural shock, natural calamity etc.</p>"
    + "<p>6. Information, communication, documentation (print &amp; electronic)</p>"
    + "<p>6.1. AOPL provides information on programs and overseas institutions in either printed form or electronically. It is"
    + "    implied</p>"
    + "<p>that all such information are true to the knowledge of AOPL and accurate at the time of publishing / posting.</p>"
    + "<p>6.2. It is implied that in case of dissimilarity in information mentioned in AOPL communication with that of Overseas"
    + "</p>"
    + "<p>Institution, Third Party Service provider, Immigration &amp; Visa Department of different countries etc. the"
    + "    information</p>"
    + "<p>mentioned on the communication and / or documentation of the Overseas Institution, Third Party Service provider,</p>"
    + "<p>Immigration &amp; Visa Department of different countries etc. will be held valid.</p>"
    + "<p>6.3. Communication made through e-mail, sms / text message, instant messenger or any other electronic channel are</p>"
    + "<p>considered as official communication by AOPL.</p>"
    + "<p>6.4. AOPL from time to time, will communicate student / parent with promotional offers and / or information"
    + "    pertaining to</p>"
    + "<p>events and programs organized by AOPL. It is understood that student / parent consents us to provide them with such"
    + "</p>"
    + "<p>information.</p>"
    + "<p>6.5. Further, student / parent also consents us to communication to reference(s) provided by them in regards to"
    + "    promotion,</p>"
    + "<p>marketing and / or invitation to event / programs.</p>"
    + "<p>6.6. AOPL communicates with student / parent via multiple channels like phone, email, postal mail etc. It is the"
    + "    responsibility</p>"
    + "<p>of the student / parent to inform and update any changes in communication address or phone numbers or other</p>"
    + "<p>communication channels. AOPL will not be responsible for any failure in communication / conveying message due to</p>"
    + "<p>such un-informed changes in communication channels.</p>"
    + "<p>6.7. Information shared with student / parent or any communication made to student / parent is confidential and"
    + "    cannot be</p>"
    + "<p>disclosed or made public.</p>"
    + "<p>6.8. Documents provided to student / parent by AOPL are confidential and cannot be disclosed or made public.</p>"
    + "<p>7. Registration / Application fees and Reimbursement policies</p>"
    + "<p>7.1. Any student intending to avail AOPL university application services is required to register with AOPL against a"
    + "</p>"
    + "<p>registration fee, payable in Indian currency.</p>"
    + "<p>7.2. Registration fee is not refundable (except as mentioned in 7.3) or transferable and does not constitute a"
    + "    deposit.</p>"
    + "<p>7.3. AOPL through its promotion may offer discount or reimburse the registration fee (in part or full) to selective"
    + "    students at</p>"
    + "<p>its discretion. Such discount or reimbursement would be in accordance to the terms &amp; conditions mentioned in the"
    + "    aliff</p>"
    + "<p><br></p>"
    + "<p>promotion offered to the student.</p>"
    + "<p>7.4. Any reimbursement made to student / parent will not bare interest.</p>"
    + "<p>7.5. Reimbursement can be claimed only after full and final payment of all other financial commitments of student /"
    + "    parent</p>"
    + "<p>towards AOPL / Overseas Institutions.</p>"
    + "<p>7.6. Any reimbursement claim made after 180 days from the date of joining the Overseas Institution will not be"
    + "    entertained.</p>"
    + "<p>7.7. Further to registration, student may be required to pay the application fee directly to the Overseas Institution"
    + "    as per its</p>"
    + "<p>described rules and regulations with discretionary assistance from AOPL. The refund of such application fee will be"
    + "    at</p>"
    + "<p>the discretion of the Overseas Institution.</p>"
    + "<p>7.8. Registration or application fees mentioned in any communication from AOPL are indicative and may vary or change"
    + "</p>"
    + "<p>during actual registration or application process. Students are advised to check with AOPL for the final registration"
    + "    or</p>"
    + "<p>application amount to be paid.</p>"
    + "<p>8. Tuition fee and refund policies.</p>"
    + "<p>8.1. Tuition Fee paid to Overseas Institution, are generally in foreign currency. Student / Parent need to consider"
    + "    and pay as</p>"
    + "<p>per the prevailing foreign exchange rate. Hence, the fee mentioned during admission may vary with the fee actually"
    + "    paid.</p>"
    + "<p>8.2. Once a student joins an overseas institution, it is the responsibility of the student / parent to know the next"
    + "    payment due</p>"
    + "<p>date and pay the tuition fee on or before the deadline.</p>"
    + "<p>8.3. Overseas Institution(s) at its own discretion may revise tuition fee, with or without prior notice. This may"
    + "    result in</p>"
    + "<p>difference between the tuition fee mentioned in offer / admission letter issued at the time of admission and the"
    + "    actual</p>"
    + "<p>tuition fee payment. Any such revision of fee is bounded upon student / parent.</p>"
    + "<p>8.4. It is the responsibility of student / parent to get familiar with the tuition fee refund policy of the overseas"
    + "    institution</p>"
    + "<p>they select.</p>"
    + "<p>8.5. Some overseas institutions demand tuition fees in advance i.e., before / after offer letter or before / after"
    + "    visa approval.</p>"
    + "<p>8.6. Students claiming refund of advance tuition fees, due to whatsoever reason, has to fulfill the formalities to"
    + "    receive</p>"
    + "<p>refund of the tuition fee paid to the overseas institution.</p>"
    + "<p>8.7. Further, refunds may be subject to certain deductions as per the policy of the overseas institution and will be"
    + "    in</p>"
    + "<p>accordance to prevailing foreign exchange rate.</p>"
    + "<p>8.8. Student / parent must provide accurate bank details and information to overseas institution for refund to take"
    + "    place.</p>"
    + "<p>8.9. AOPL at its discretion may assist student / parent to get refund of tuition fees paid to overseas institution."
    + "    However,</p>"
    + "<p>AOPL is not responsible or liable in any way for refunds of overseas institution tuition fees. Any loss whether"
    + "    financial or</p>"
    + "<p>otherwise due to delay in such refund is not on AOPL.</p>"
    + "<p>9. Legal Jurisdiction</p>"
    + "<p>9.1. Disputes if any, to be resolved in accordance to Indian law.</p>"
    + "<p>9.2. All such disputes are subject to Mumbai jurisdiction only.</p>"